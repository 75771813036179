/* Override styles globally for input text fields */
img {
  cursor:  pointer !important;
}
.p-inputtext {
  color: #000 !important;
  font-size: 12px !important;
  height: 28px !important;
  font-feature-settings: var(--font-feature-settings, normal);
  border: 1px solid #d1d5db !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
  appearance: none !important;
  border-radius: 6px !important;
  font-family: AvenirNextCyrMedium !important;
}

.p-dropdown {
  border: 1px solid #d1d5db !important;
}

.pi-cog {
  color: #030927 !important;
}

.p-inputtext:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

/* Override styles for checkbox */

.p-checkbox-box {
  border: 2px solid #ced4da !important;
  background: #ffffff !important;
  color: #495057 !important;
  border-radius: 4px !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.p-checkbox .p-checkbox-box {
  width: 21px !important;
  height: 21px !important;
  margin-top: 0px !important;
}
.p-checkbox .p-checkbox-box .p-icon{
  font-family: AvenirNextCyrMedium !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: none !important;
}

.p-checkbox-icon {
  transition-duration: 0.2s !important;
  color: #ffffff !important;
  font-size: 14px !important;
}

.p-highlight {
  border-color: #4ea732 !important;
  background: #4ea732 !important;
}
.p-focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  border-color: #4ea732 !important;
}

.p-highlight:hover {
  color: #ffffff !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none !important;
}

.p-checkbox-label{
  color: #6B6C71 !important;
  font-size: 14px !important;
  font-family: AvenirNextCyrMedium !important;
}
.columtextboxCss{
  color: #6B6C71 !important;
  font-size: 16px !important;
  font-family: AvenirNextCyr !important;
}
.columnHeaderCss{
  color: #393A3D !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: AvenirNextCyr !important;
}
.p-datatable-thead>tr>th {
  padding: 7px 7px !important;
  background-color: #f1f1f1 !important;
}

.p-datatable-tbody>tr>td {
  padding: 5px 5px !important;
  border-width: 1px 1px 1px 0px !important;
  padding-left: 12px !important;
}

.pi-search {
  color: #030e4c !important;
  font-weight: 600;
  font-size: 13px !important;
  margin-left: 7px !important;
}


.btn-img{
  width: 16px !important;
  height: 18px !important;
  margin-left: 18px !important;
  cursor: pointer !important;
}

.pi-cog {
  font-size: 16px !important;
}

.p-datatable .p-datatable-thead>tr>th {
  font-family: AvenirNextCyr !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  font-weight: 600 !important;
  padding: 7px 7px !important;
  border: 1px solid #CCCCCC !important;
  border-width: 0px 0px 3px 0 !important;
  color: #767676 !important;
  background: #fff !important;
  transition: box-shadow 0.2s !important;
  padding-left: 12px !important;
  text-transform: capitalize;
  padding-right: 0px !important;
  font-size: 14px !important;
}

.p-datatable .p-datatable-thead>tr>th:hover {
  background-color: none !important;
  cursor: pointer !important;
  border-width: 0px 0px 3px 0 !important;
}

.p-datatable .p-datatable-tbody>tr {
  background: #ffffff !important;
  color: #767676 !important;
  transition: box-shadow 0.2s !important;
  height: 40px !important;
  font-size: 12px !important;
  font-family: AvenirNextCyrMedium !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
  padding: 0px !important;
  padding-left: 12px !important;
  border-bottom: 0 !important;
}

.p-datatable-table {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.p-dialog-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-dialog-content {
  padding-bottom: 15px;
  overflow: hidden;
}

.card {
  background: var(--surface-card) !important;
  border: 1px solid var(--surface-border) !important;
  padding: 2rem !important;
  margin-bottom: 2rem !important;
  box-shadow: var(--card-shadow) !important;
  border-radius: 12px !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #EEF2FF !important;
  border-color: #EEF2FF !important;
  color: #030E4C !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border: 0 none;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 0 0 !important;
}

.p-inputtext:enabled:hover {
  border: 1px solid #2ca01c !important;
  border-top: 1px solid #2ca01c !important;
  border-bottom: 1px solid #2ca01c !important;
  border-left: 1px solid #2ca01c !important;
  border-right: 1px solid #2ca01c !important;
}


.p-inputtext:enabled:focus {
  border:1px solid #2ca01c !important
}

.p-dropdown:not(.p-disabled):hover {
  border:1px solid #2ca01c !important
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #E4E4E4 !important;
  color: #514f4f;
  font-family: AvenirNextCyrMedium !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #514f4f !important;
  font-family: AvenirNextCyrMedium !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #514f4f !important;
  font-family: AvenirNextCyrMedium !important;
}

.btn-common-css {
  padding: 5px 10px !important;
  background: #18cd1f !important;
  border: 1px solid #18cd1f !important;
  color: #fff !important;
  font-weight: bold;
  font-family: AvenirNextCyr !important;
  border-radius: 5px;
  height: 34px !important;
}

.p-avatar {
  border-radius: 50% !important;
}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded>.p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-menubutton.p-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: none !important;
  color: #3276cb !important;
}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded>.p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-menubutton.p-button:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-right: none !important;
  color: #3276cb !important;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-dialog .p-dialog-header {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  background-color: rgb(244 244 244) !important;
  padding-right: 0px;
  padding-left: 1.5rem;
  font-family: AvenirNextCyrMedium !important;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 15px !important;
}
.dialog-header{
  padding: 0.9rem !important;
  padding-top:0.9rem !important;
  padding-bottom: 0.9rem !important;;
  background-color: rgb(244 244 244) !important;
  font-family: AvenirNextCyrMedium !important;
}
.dialog-close{
  cursor: pointer !important;
}

.p-dialog {
  height: 480px !important;
  overflow: auto !important;
  margin-bottom: 40px !important;
  border-radius: 10px;
}

.icon-container {
  display: flex !important;
  justify-content: space-between !important; /* Spreads items between both sides */
  align-items: center !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  background: #fff !important;
  display: flex !important;
  justify-content: flex-end !important;
  padding: 0.75rem !important;
  border: 1px solid #ffffff;
}

.btn-default-css {
  font-family: AvenirNextCyrMedium !important;
  font-size: 16px !important;
  height: 40px !important;
  border-radius: 4px;
  padding: 5px 15px;
}

// Grid Column Configure Popup Styles


.p-button:focus {
  outline: 0 none !important;
  outline-offset: 0;
  box-shadow: none !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon .p-icon {
  color: #030927 !important;
  cursor: pointer;
  height: 22px !important;
  width: 22px !important;
  border-radius: 22px;
  display: inline-block;
  margin-right: 7px !important;
  padding: 5px !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon .p-icon:hover {
  color: #030927 !important;
  background: none !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  background-color: #e9ecef !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus{
  box-shadow: none !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover{
  background:none !important;
}

.p-overlaypanel .p-overlaypanel-close {
  background: none !important;
  outline: none !important;
  right: 10px !important;
  top: 10px !important;
  color: #000 !important;
  padding: 4px;
  border-radius:22px;
}

.p-overlaypanel:before{
  display: none !important;
}
.p-confirm-dialog {
  height: 22% !important;
  width: 35% !important;
}

.btnColumnCancel {
  border: 1px solid #393b3d !important;
  background-color: #fff !important;
  color: #393A3D !important;
  padding: 5px 15px !important;
  border-radius: 4px !important;
  font-family: AvenirNextCyrMedium !important;
  transition: 0.4s important;
  font-size: 15px !important;
}
.btnColumnCancel:hover {
  background-color: #4ea732 !important;
  border: 1px solid #4ea732 !important;
  color: #fff !important;
}
.btnColumnApply {
  border: 1px solid #393b3d !important;
  background-color: #fff !important;
  color: #393A3D !important;
  padding: 5px 15px !important;
  border-radius: 4px !important;
  font-family: AvenirNextCyrMedium !important;
  transition: 0.4s important;
  font-size: 15px !important;
}
.btnColumnApply:hover {
  background-color: #4ea732 !important;
  border: 1px solid #4ea732 !important;
  color: #fff !important;
}
.btnShipColumnCancel{
  border: 1px solid #393b3d !important;
  background-color: #fff !important;
  color: #393A3D !important;
  padding: 5px 15px !important;
  border-radius: 4px !important;
  font-family: AvenirNextCyrMedium !important;
  transition: 0.4s important;
  font-size: 15px !important;
}
.btnShipColumnCancel:hover{
  background-color: #4ea732 !important;
  border: 1px solid #4ea732 !important;
  color: #fff !important;
}
.btnShipColumnApply{
  border: 1px solid #393b3d !important;
  background-color: #fff !important;
  color: #393A3D !important;
  padding: 5px 15px !important;
  border-radius: 4px !important;
  font-family: AvenirNextCyrMedium !important;
  transition: 0.4s important;
  font-size: 15px !important;
}
::ng-deep .btnColumnCancel:hover {
  background-color: #4ea732 !important;
  border: 1px solid #4ea732 !important;
  color: #fff !important;
}
.btnShipColumnApply:hover{
  background-color: #4ea732 !important;
  border: 1px solid #4ea732 !important;
  color: #fff !important;
}
.p-overlaypanel:after{
  display: none !important;
}
.btnSave > .p-button > .p-tieredmenu {
  width: 11rem !important;
}

.icon-container img {
  padding: 4px;
  border-radius:22px;
  display: inline-block;
}

.filter-icon:hover {
  background-color:#e9ecef; 
}

.download-icon:hover {
  background-color: #e9ecef;
}

.setting-icon:hover {
  background-color: #e9ecef; 
  line-height: 1;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 8px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  font-family: AvenirNextCyrMedium !important;
}

.columnconfig {
  border-radius: 10px !important;
}

.p-link:focus{
  box-shadow: none !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  width: 0.8rem !important;
  height: 0.8rem !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

.fa-icon img {
  padding: 4px !important;
  border-radius: 22px !important;
  display: inline-block !important;
}

.close-icon-img {
  padding: 4px;
  border-radius: 22px;
  display: inline-block;
}

.dialog-close {
  padding: 4px;
  border-radius: 22px;
  display: inline-block;
}

.dialog-close:hover {
  background-color: #e9ecef !important;
}


.p-overlaypanel .p-overlaypanel-close:hover {
  background-color: #e9ecef !important;
}

.close-icon-img:hover {
  background-color: #e9ecef !important;
}
svg.p-dialog-header-close-icon.p-icon:hover {
  background-color: #e9ecef !important;
}

.fa-address-book:hover {
  background-color: #e9ecef !important;
}

.layout-wrapper .layout-topbar .topbar-menu > li {
  border-radius: 22px;
}

.layout-wrapper .layout-topbar .topbar-menu > li:hover {
  background-color: #e9ecef !important;
}
