
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ShipV1-primary: mat.define-palette(mat.$indigo-palette);
$ShipV1-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ShipV1-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ShipV1-theme: mat.define-light-theme((
  color: (
    primary: $ShipV1-primary,
    accent: $ShipV1-accent,
    warn: $ShipV1-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ShipV1-theme);

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
//@import "../node_modules/prismjs/themes/prism-coy.css";
body {
    font-family: AvenirNextCyr !important;
    overflow-x: hidden !important;
    // color: #4e4a4a !important;
    // font-weight: 600;
    // line-height: 30px;
    // color: #5f676e !important;
    
  }
  @font-face {
    font-family: 'AvenirNextCyr';
    src: local('AvenirNextCyr'), url(./assets/fonts/avenir-next/AvenirNextCyr-Regular.woff) format('woff');
  }
  @font-face {
    font-family: 'AvenirNextCyrMedium';
    src: local('AvenirNextCyrMedium'), url(./assets/fonts/avenir-next/AvenirNextCyr-Medium.woff) format('woff');
  }
  .layout-wrapper .layout-main{
    background-color:#f4f5f8;    
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 14, 76, 0.25) !important; /* #030E4C with 25% opacity */
    border-color: #030E4C !important; /* Theme color */
}

input:focus {
  border-color: #030E4C !important;
  box-shadow: 0 0 0 2px rgba(3, 14, 76, 0.2) !important;
}

::ng-deep body {
  overflow-x: hidden !important;
}

// // customized scroll bar 

// /* For Webkit browsers (Chrome, Safari) */
// ::-webkit-scrollbar {
//   width: 14px; /* Adjust width as needed */
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #030E4C; /* Dark blue color */
//   border-radius: 8px; /* Round the corners */
//   border: 4px solid #f0f0f0; /* Light color border to enhance the pipe effect */
//   transition: background-color 0.3s, border-color 0.3s, transform 0.3s; /* Smooth color transition */
//   animation: pulse 1.5s infinite; /* Add animation */
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: #020A3C; /* Slightly darker color on hover */
//   border-color: #e0e0e0; /* Change border color on hover */
//   animation: none; /* Stop animation on hover */
// }

// ::-webkit-scrollbar-track {
//   background-color: #f0f0f0; /* Light background for the track */
// }

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 23px;
  height: 23px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0 !important;
}

::-webkit-scrollbar-track {
  background-color: #bbbbbb;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb {
  background-color: #bbbbbb;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.scroller {
  --scrollbar-color-thumb: #bbbbbb !important;
  --scrollbar-color-track: #f0f0f0 !important;
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 15px;
}


/* For Firefox */
// * {
//   scrollbar-color: #bbbbbb #f0f0f0; /* Thumb and track colors */
// }

// *:hover {
//   scrollbar-height: 10% !important;
//   scrollbar-color: #949393 #dad9d9; /* Thumb and track colors */
// }

html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


:host ::ng-deep .p-inputtext {
  height: 27px !important;
  width: 100% !important;
}
@import url("./assets/custom-css.scss");

.p-dropdown:not(.p-disabled).p-focus {
  border-color: #2ca01c !important;
  box-shadow: none !important;
}
.p-datatable .p-datatable-tbody > tr:hover {
  background-color: #f1f1f1 !important; /* Light gray background for hover */
  cursor: pointer !important; /* Change cursor to pointer */
}
.p-datatable .p-datatable-tbody > tr {
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

polygon {
  transform: translateY(0);
  position: relative;
  transition: transform 0.3s, fill 0.3s, fill-opacity 0.3s, stroke-opacity 0.3s;
}

.svg-viewer th {
  text-transform: uppercase;
  text-align: left;
  color: #666;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: normal;
}

.x-ray polygon {
  fill-opacity: 0.1;
  stroke-opacity: 0.2;
}

.x-ray polygon.active {
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important;
}

line.volume-line {
  stroke: #666;
  stroke-dasharray: 2, 1;
  stroke-width: 1;
}

polygon.volume-line {
  stroke: #666;
}

.svg-viewer ul.legend li {
  cursor: pointer;
}

.svg-viewer table {
  max-width: 100%;
}

figure {
  width: 400px;
}

polygon {
  transform: translateY(0);
  position: relative;
  transition: transform 0.3s, fill 0.3s, fill-opacity 0.3s, stroke-opacity 0.3s;
}

.svg-viewer th {
  text-transform: uppercase;
  text-align: left;
  color: #000000;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 600;
}

.x-ray polygon {
  fill-opacity: 0.1;
  stroke-opacity: 0.2;
}

.x-ray polygon.active {
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important;
}

line.volume-line {
  stroke: #666;
  stroke-dasharray: 2, 1;
  stroke-width: 1;
}

polygon.volume-line {
  stroke: #666;
}

ul.legend li {
  cursor: pointer;
}

table {
  max-width: 100%;
}

figure {
  width: 400px;
}

// polygon {
//   transition: transform 0.2s, fill-opacity 0.2s, stroke-opacity 0.2s;
//   fill-opacity: 1;
//   stroke-opacity: 1;
// }

polygon.shape-hidden {
  opacity: 0;
  fill-opacity: 0;
  stroke-opacity: 0;
  transform: translateY(-50%);
}

polygon{
  transform: translateY(0);
  position: relative;
  transition:transform .3s, fill .3s, fill-opacity .3s, stroke-opacity .3s;
  }
  

.svg-viewer h3 {
  color: #b9b9b9;
  font-weight: normal;
}

.svg-viewer #btn-wrap {
  display: inline-block;
  text-align: center;
  width: 550px;
}

.svg-viewer button {
  padding: 5px 20px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(37 29 68 / 24%);
  border-radius: 10px;
  font-size: 1.25rem;
  margin-left: 1.25rem;
  transition: 0.3s background-color;
  color: #251d44;
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
  border-color: transparent;
  cursor: pointer;
}

.svg-viewer button:hover {
  background: #f9f8fa;
}

.fa-icon{
  width:30px;
  cursor:pointer;
  padding: 4px !important;
  border-radius: 22px !important;
  display: inline-block !important;
}
.fa-icon:hover{
  background-color: #e9ecef;
  line-height: 1;
}

